import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const Sidebar = ({ authUser }) =>
  authUser && (
    <div className="left-side-menu left-side-menu-detached mm-active">
      <div className="leftbar-user">
        <a href="#">
          <img
            src={authUser.avatar_url}
            alt="user-image"
            height="42"
            className="rounded-circle shadow-sm"
          />
          <span className="leftbar-user-name">
            {authUser.username}
          </span>
        </a>
      </div>

      {/* <!--- Sidemenu --> */}
      <ul className="metismenu side-nav mm-show">
        <li className="side-nav-title side-nav-item">Navigation</li>

        <li className="side-nav-item mm-active">
          <Link className="side-nav-link active" to={ROUTES.HOME}>
            <i className="uil-home-alt"></i>
            {/* <span className="badge badge-info badge-pill float-right">
          4
        </span> */}
            <span> Sites </span>
          </Link>
          {/* <ul
        className="side-nav-second-level mm-collapse mm-show"
        aria-expanded="false"
      >
        <li>
          <a href="dashboard-analytics.html">Analytics</a>
        </li>
        <li>
          <a href="dashboard-crm.html">CRM</a>
        </li>
        <li className="mm-active">
          <a href="index.html" className="active">
            Ecommerce
          </a>
        </li>
        <li>
          <a href="dashboard-projects.html">Projects</a>
        </li>
      </ul> */}
        </li>
        {!!authUser.roles[ROLES.ADMIN] && (
          <li className="side-nav-item">
            <Link className="side-nav-link" to={ROUTES.SERVERS}>
              <i className="uil-home-alt"></i>
              <span> Servers </span>
            </Link>
          </li>
        )}

        {/* <li className="side-nav-title side-nav-item">Apps</li>

    <li className="side-nav-item">
      <a href="apps-calendar.html" className="side-nav-link">
        <i className="uil-calender"></i>
        <span> Calendar </span>
      </a>
    </li>

    <li className="side-nav-item">
      <a href="apps-chat.html" className="side-nav-link">
        <i className="uil-comments-alt"></i>
        <span> Chat </span>
      </a>
    </li>

    <li className="side-nav-item">
      <a href="#" className="side-nav-link">
        <i className="uil-store"></i>
        <span> Ecommerce </span>
        <span className="menu-arrow"></span>
      </a>
      <ul
        className="side-nav-second-level mm-collapse"
        aria-expanded="false"
      >
        <li>
          <a href="apps-ecommerce-products.html">Products</a>
        </li>
        <li>
          <a href="apps-ecommerce-products-details.html">
            Products Details
          </a>
        </li>
        <li>
          <a href="apps-ecommerce-orders.html">Orders</a>
        </li>
        <li>
          <a href="apps-ecommerce-orders-details.html">
            Order Details
          </a>
        </li>
        <li>
          <a href="apps-ecommerce-customers.html">Customers</a>
        </li>
        <li>
          <a href="apps-ecommerce-shopping-cart.html">
            Shopping Cart
          </a>
        </li>
        <li>
          <a href="apps-ecommerce-checkout.html">Checkout</a>
        </li>
        <li>
          <a href="apps-ecommerce-sellers.html">Sellers</a>
        </li>
      </ul>
    </li>

    <li className="side-nav-item">
      <a href="#" className="side-nav-link">
        <i className="uil-envelope"></i>
        <span> Email </span>
        <span className="menu-arrow"></span>
      </a>
      <ul
        className="side-nav-second-level mm-collapse"
        aria-expanded="false"
      >
        <li>
          <a href="apps-email-inbox.html">Inbox</a>
        </li>
        <li>
          <a href="apps-email-read.html">Read Email</a>
        </li>
      </ul>
    </li>

    <li className="side-nav-item">
      <a href="#" className="side-nav-link">
        <i className="uil-briefcase"></i>
        <span> Projects </span>
        <span className="menu-arrow"></span>
      </a>
      <ul
        className="side-nav-second-level mm-collapse"
        aria-expanded="false"
      >
        <li>
          <a href="apps-projects-list.html">List</a>
        </li>
        <li>
          <a href="apps-projects-details.html">Details</a>
        </li>
        <li>
          <a href="apps-projects-gantt.html">
            Gantt
            <span className="badge badge-pill badge-dark-lighten font-10 float-right">
              New
            </span>
          </a>
        </li>
        <li>
          <a href="apps-projects-add.html">
            Create Project
            <span className="badge badge-pill badge-success-lighten font-10 float-right">
              New
            </span>
          </a>
        </li>
      </ul>
    </li>

    <li className="side-nav-item">
      <a href="apps-social-feed.html" className="side-nav-link">
        <i className="uil-rss"></i>
        <span> Social Feed </span>
      </a>
    </li>

    <li className="side-nav-item">
      <a href="#" className="side-nav-link">
        <i className="uil-clipboard-alt"></i>
        <span> Tasks </span>
        <span className="menu-arrow"></span>
      </a>
      <ul
        className="side-nav-second-level mm-collapse"
        aria-expanded="false"
      >
        <li>
          <a href="apps-tasks.html">List</a>
        </li>
        <li>
          <a href="apps-tasks-details.html">Details</a>
        </li>
        <li>
          <a href="apps-kanban.html">Kanban Board</a>
        </li>
      </ul>
    </li> */}
      </ul>

      {/* <!-- End Sidebar --> */}

      <div className="clearfix"></div>
      {/* <!-- Sidebar -left --> */}
    </div>
  );

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Sidebar);
