import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const Navigation = ({ authUser }) => (
  <div>
    {/* <div className="container-fluid"> */}
      {/* <!-- LOGO --> */}
      {/* <a href="index.html" className="topnav-logo">
        <span className="topnav-logo-lg">
          <img
            src="assets/images/logo-light.png"
            alt=""
            height="16"
          />
        </span>
        <span className="topnav-logo-sm">
          <img src="assets/images/logo_sm.png" alt="" height="16" />
        </span>
      </a> */}

    <ul className="list-unstyled m-0 navbar-container">
        {authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )}
      </ul>
      {/* <a className="button-menu-mobile disable-btn">
        <div className="lines">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </a> */}
      {/* <div className="app-search">
        <form>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
            />
            <span className="mdi mdi-magnify"></span>
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>
      </div> */}
    {/* </div> */}
  </div>
);

const NavigationAuth = ({ authUser }) => (
  <>
    <li className="dropdown navbar-items">
      <a
        className="nav-link dropdown-toggle nav-user arrow-none mr-0"
        data-toggle="dropdown"
        id="topbar-userdrop"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="account-user-avatar">
          <img
            src={authUser.avatar_url}
            alt="user-image"
            className="rounded-circle"
          />
        </span>
        <span>
          <span className="account-user-name">
            {authUser.username}
          </span>
          <span className="account-position">Admin</span>
        </span>
      </a>
      <div
        className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
        aria-labelledby="topbar-userdrop"
      >
        {/* <!-- item--> */}
        <div className=" dropdown-header noti-title">
          <h6 className="text-overflow m-0">Welcome !</h6>
        </div>

        {/* <!-- item--> */}
        <a href="#" className="dropdown-item notify-item">
          <i className="mdi mdi-account-circle mr-1"></i>
          <span>My Account</span>
        </a>

        {/* <!-- item--> */}
        <a href="#" className="dropdown-item notify-item">
          <i className="mdi mdi-account-edit mr-1"></i>
          <span>Settings</span>
        </a>

        {/* <!-- item--> */}
        <a href="#" className="dropdown-item notify-item">
          <i className="mdi mdi-lifebuoy mr-1"></i>
          <span>Support</span>
        </a>

        {/* <!-- item--> */}
        <a href="#" className="dropdown-item notify-item">
          <i className="mdi mdi-lock-outline mr-1"></i>
          <span>Lock Screen</span>
        </a>

        {/* <!-- item--> */}
        <a href="#" className="dropdown-item notify-item">
          <i className="mdi mdi-logout mr-1"></i>
          <span>Logout</span>
        </a>
      </div>
    </li>
    <li className="dropdown navbar-items">
      <SignOutButton />
    </li>
  </>
);

const NavigationNonAuth = () => (
  <>
    {/* <li className="dropdown notification-list">
      <Link className="nav-link" to={ROUTES.LANDING}>
        <span className="noti-icon">Landing</span>
      </Link>
    </li> */}
    <li className="dropdown navbar-items sign-in">
      <Link className="nav-link" to={ROUTES.SIGN_IN}>
        <span className="noti-icon">Sign In</span>
      </Link>
    </li>
  </>
);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);
