import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <a href="#" className="nav-link" onClick={firebase.doSignOut}>
    <span className="noti-icon">Sign Out</span>
  </a>
);

export default withFirebase(SignOutButton);
