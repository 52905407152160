import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ROUTES from '../../constants/routes';

const Landing = ({ authUser }) =>
  // <div>
  //   <h1>Landing</h1>
  // </div>
  authUser ? (
    <Redirect to={ROUTES.HOME} />
  ) : (
    <Redirect to={ROUTES.SIGN_IN} />
  );

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Landing);
