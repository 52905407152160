import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

class ServersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.servers.length) {
      this.setState({ loading: true });
    }

    this.onListenForServers();
  }

  componentDidUpdate(props) {
    if (props.limit !== this.props.limit) {
      this.onListenForServers();
    }
  }

  onListenForServers = () => {
    this.props.firebase
      .servers()
      .limitToLast(this.props.limit)
      .on('value', (snapshot) => {
        this.props.onSetServers(snapshot.val());

        this.setState({ loading: false });
      });
  };

  componentWillUnmount() {
    this.props.firebase.servers().off();
  }

  onCreateMessage = (event, authUser) => {
    this.props.firebase.servers().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    this.props.firebase.message(message.uid).set({
      ...messageSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveServer = (ipString) => {
    this.props.firebase
      .server(ipString)
      .remove()
      .catch((err) => {
        alert(err.message);
      });
  };

  onNextPage = () => {
    this.props.onSetServersLimit(this.props.limit + 5);
  };

  render() {
    const { servers } = this.props;
    const { text, loading } = this.state;

    return (
      <div className="">
        {/* <div className="row">
          <div className=" col-md-8 site-content offset-md-2"> */}
                    <div className="table-responsive-sm">
                      <table className="table table-centered mb-0">
                        <thead>
                          <tr>
                            <th>Server Name</th>
                            <th>IP address</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {servers &&
                            servers.map((server) => (
                              <tr key={server.ipString}>
                                <td>{server.name}</td>
                                <td>{server.ip_address}</td>
                                <td>
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() =>
                                      this.onRemoveServer(
                                        server.ipString,
                                      )
                                    }
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
          {/* </div>
        </div> */}
        {/* {!loading && servers && (
          <button type="button" onClick={this.onNextPage}>
            More
          </button>
        )} */}

        {loading && <div>Loading ...</div>}

        {!servers && <div>There are no servers ...</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  servers: Object.keys(state.serverState.servers || {}).map(
    (key) => ({
      ...state.serverState.servers[key],
      ipString: key,
    }),
  ),
  limit: state.serverState.limit,
});

const mapDispatchToProps = (dispatch) => ({
  onSetServers: (servers) =>
    dispatch({ type: 'SERVERS_SET', servers }),
  onSetServersLimit: (limit) =>
    dispatch({ type: 'SERVERS_LIMIT_SET', limit }),
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(ServersList);
