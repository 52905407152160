const INITIAL_STATE = {
  servers: null,
  limit: 5,
};

const applySetServers = (state, action) => ({
  ...state,
  servers: action.servers,
});

const applySetServersLimit = (state, action) => ({
  ...state,
  limit: action.limit,
});

function serverReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SERVERS_SET': {
      return applySetServers(state, action);
    }
    case 'SERVERS_LIMIT_SET': {
      return applySetServersLimit(state, action);
    }
    default:
      return state;
  }
}

export default serverReducer;
