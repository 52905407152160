import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import _ from 'lodash';
import { withFirebase } from '../Firebase';
import MessageList from '../Messages/MessageList';
import { Link } from 'react-router-dom';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

class Servers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      sites: [],
    };
  }

  componentDidMount() {
    if (!this.props.servers.length) {
      this.setState({ loading: true });
    }

    this.onListenForMessages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.limit !== this.props.limit) {
      this.onListenForMessages();
    }

    if (prevProps.servers !== this.props.servers) {
      // console.log(
      //   'this.props.servers',
      //   prevProps.servers,
      //   this.props.servers,
      // );
      let sites = [];
      // this.props.servers.forEach((server) =>
      //  sites.push(
      //    ...Object.keys(server.sites || {}).map((key) => ({
      //      ...server.sites[key],
      //      urlString: key,
      //      ip_address: server.ip_address,
      //      server_name: server.name,
      //    })),
      // ),
      // );
	this.props.servers.forEach(function(server) {
            sites.push(
                ...Object.keys(server.sites || {}).map((key) => {
                    let user_url = "";
                    if(server.user){
                        user_url = `${server.user}@${server.ip_address}`
                    }
                  return {
                    ...server.sites[key],
                    user: server.user,
                    user_url,
                    urlString: key,
                    ip_address: server.ip_address,
                    server_name: server.name,
                }}),
            )
        }
      );

      this.setState({
        filteredSites: sites,
        sites,
      });
    }
  }

  onListenForMessages = () => {
    this.props.firebase
      .servers()
      .limitToLast(this.props.limit)
      .on('value', (snapshot) => {
        this.props.onSetServers(snapshot.val());

        this.setState({ loading: false });
      });
  };

  componentWillUnmount() {
    this.props.firebase.servers().off();
  }

  onChangeSearch = (event) => {
    const query = event.target.value;
    console.log('search query', query);
    console.log(this.state);
    this.setState((prevState) => ({
      filteredSites:
        query.length > 0
          ? prevState.sites.filter(
              (site) =>
                site.url
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) !== -1,
            )
          : prevState.sites,
    }));
  };

  onChangeServer = (event) => {
    const ipString = event.target.value;
    let sites = [];
    if (ipString === 'all') {
      // this.props.servers.forEach((server) =>
      //  sites.push(
      //    ...Object.keys(server.sites || {}).map((key) => ({
      //      ...server.sites[key],
      //      urlString: key,
      //      ip_address: server.ip_address,
      //      server_name: server.name,
      //    })),
      //  ),
      // );
	this.props.servers.forEach((server) =>
        sites.push(
          ...Object.keys(server.sites || {}).map((key) => {
              let user_url = "";
              if(server.user){
                  user_url = `${server.user}@${server.ip_address}`
              }
              return {
                ...server.sites[key],
                user: server.user,
                user_url,
                urlString: key,
                ip_address: server.ip_address,
                server_name: server.name,
              }
          }),
        ),
      );
    } else {
      const server = this.props.servers.find(
        (server) => server.ipString === ipString,
      );
      // sites = Object.keys(server.sites || {}).map((key) => ({
      //  ...server.sites[key],
      //  urlString: key,
      //  ip_address: server.ip_address,
      //  server_name: server.name,
      // }));
	sites = Object.keys(server.sites || {}).map((key) => {
          let user_url = "";
          if(server.user){
              user_url = `${server.user}@${server.ip_address}`
          }
          return {
            ...server.sites[key],
            user: server.user,
            user_url,
            urlString: key,
            ip_address: server.ip_address,
            server_name: server.name,
          }
      });
    }

    this.setState({
      filteredSites: sites,
      sites,
    });
  };

  onCreateMessage = (event, authUser) => {
    this.props.firebase.servers().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    this.props.firebase.message(message.uid).set({
      ...messageSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveMessage = (uid) => {
    this.props.firebase.message(uid).remove();
  };

  onNextPage = () => {
    this.props.onSetServersLimit(this.props.limit + 5);
  };

  render() {
    const { servers } = this.props;
    const { text, loading } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className=" col-md-10 site-content offset-md-1">
            <div className="">
              <div className="">
                {/* <h4 className="header-title">Basic example</h4>
                <p className="text-muted font-14">
                  For basic styling—light padding and only horizontal
                  dividers—add the base class <code>.table</code> to
                  any <code>&lt;table&gt;</code>.
                </p> */}
                <div className="row align-items-center">
                  {/* <div className="col-sm-12 col-md-4"> */}
                    {/* <div
                      className="dataTables_length"
                      id="basic-datatable_length"
                    >
                      <label>
                        Show{' '}
                        <select
                          name="basic-datatable_length"
                          aria-controls="basic-datatable"
                          className="custom-select custom-select-sm form-control form-control-sm"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{' '}
                        entries
                      </label>
                    </div> */}
                  {/* </div> */}
                  <div className="col-md-6">
                    <div
                      id="basic-datatable_filter"
                      className="dataTables_filter"
                    >
                      <label style={{ width: '100%' }}>
                        Search:
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          aria-controls="basic-datatable"
                          onKeyUp={this.onChangeSearch}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {servers && (
                      <label style={{ width: '100%' }}>
                        Server:
                        <select
                          onChange={this.onChangeServer}
                          defaultValue={''}
                          className="form-control"
                        >
                          <option disabled value="">
                            Server
                          </option>
                          <option value="all">All</option>
                          {servers.map((server) => (
                            <option
                              key={server.ipString}
                              value={server.ipString}
                            >
                              {server.name}
                            </option>
                          ))}
                        </select>
                      </label>
                    )}

                   
                  </div>
                  <div className="col-md-2 text-center">
                    {!!this.props.authUser.roles[ROLES.ADMIN] && (
                      <div className="side-nav-item ">
                        <Link className="side-nav-link" to={ROUTES.SERVERS}>
                          <i className="uil-home-alt"></i>
                          <span> Servers </span>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 ">
                    <div className="table-responsive-sm">
                      <table className="table  table-centered mb-0">
                        <thead >
                          <tr>
        		    <th>SSH User</th>
                            <th>SSH User URL</th>	 
	                    <th>Site URL</th>
                            <th>IP address</th>
                            <th>Server name</th>
                            {/* <th>Amount</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filteredSites &&
                            this.state.filteredSites.map((site, index) => (
                              <tr key={site.urlString + '_' + index}>
                            	<td>{site.user}</td>
                                <td>{site.user_url}</td>
    				<td>{site.url}</td>
                                <td>{site.ip_address}</td>
                                <td>
                                  <span className="badge badge-primary">
                                    {site.server_name}
                                  </span>
                                </td>
                                {/* <td>$6,518.18</td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {!loading && servers && (
          <button type="button" onClick={this.onNextPage}>
            More
          </button>
        )} */}

        {loading && <div>Loading ...</div>}

        {!servers && <div>There are no servers ...</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  servers: Object.keys(state.serverState.servers || {}).map(
    (key) => ({
      ...state.serverState.servers[key],
      ipString: key,
    }),
  ),
  limit: state.serverState.limit,
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = (dispatch) => ({
  onSetServers: (servers) =>
    dispatch({ type: 'SERVERS_SET', servers }),
  onSetServersLimit: (limit) =>
    dispatch({ type: 'SERVERS_LIMIT_SET', limit }),
  
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(Servers);
