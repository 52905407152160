import React from 'react';
const Footer = () => (
  <footer className="footer-container">
        <div className="footer-content">
          2018 - 2020 © Sifars - sifars.com
        </div>
        {/* <div className="col-md-6">
          <div className="text-md-right footer-links d-none d-md-block">
            <a href="#">About</a>
            <a href="#">Support</a>
            <a href="#">Contact Us</a>
          </div>
        </div> */}
  </footer>
);
export default Footer;
