import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import LandingPage from '../Landing';
// import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
// import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
// import AccountPage from '../Account';
import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => (
  <Router>
    <div className="main-container">
      <Navigation />

          {/* <!-- ========== Left Sidebar Start ========== --> */}
      {/* <div className="sidebar">
            <Sidebar />
          </div> */}
          {/* <!-- Left Sidebar End --> */}


              <Route
                exact
                path={ROUTES.LANDING}
                component={LandingPage}
              />

                {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
                <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                {/* <Route
                  path={ROUTES.PASSWORD_FORGET}
                  component={PasswordForgetPage}
                /> */}

               <div className="home-page">
                <Route path={ROUTES.HOME} component={HomePage} />
              </div>

              {/* <Route path={ROUTES.ACCOUNT} component={AccountPage} /> */}
              <Route path={ROUTES.ADMIN} component={AdminPage} />

            {/* <!-- Footer Start --> */}
              <Footer />
            {/* <!-- end Footer --> */}
        </div>
  </Router>
);

export default withAuthentication(App);
