import React from 'react';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import * as ROUTES from '../../constants/routes';
import Servers from '../Servers';
import { Link } from 'react-router-dom';

const HomePage = () => (
  <div>
    {/* <!-- start page title --> */}
    {/* <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={ROUTES.LANDING}>Sifars</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={ROUTES.HOME}>Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Sites</li>
            </ol>
          </div>
          <h4 className="page-title">Sites</h4>
        </div>
      </div>
    </div> */}
    {/* <!-- end page title -->  */}

    {/* <Messages /> */}
    <Servers />
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
