import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import axios from 'axios';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const SignInPage = () => (
  <div className="signin-page">
    <div className="container-fluid">
    <div className="row">
        <div className="col-10 offset-1 col-md-4 offset-md-4 text-right">
        <div className="login-title">
        <h1>SignIn</h1>
        </div>
      <SignInForm />
        <div>
          <p className="line-break">OR</p>
        </div>
        <div>
          <SignInGitlab />
        </div>
        <div className="col-md-3">{/* <SignInGoogle /> */}</div>
      
      {/* <PasswordForgetLink /> */}
      {/* <SignUpLink /> */}
    </div>
    </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-field">
          <input
            className="form-control"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </div>
        <div className="input-field">
          <input
            className="form-control "
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
        </div>
        <div>
          <button
            disabled={isInvalid}
            type="submit"
            className="sigin-btn btn  btn-primary"
          >
            Sign In
          </button>
        </div>
        {error && <p className="error-message">{error.message}</p>}
      </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInGitlabBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search);
    if (query.has('code') && query.has('state')) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/auth/gitlab`,
        data: {
          code: query.get('code'),
        },
      })
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            let roles = {};
            if (response.data.is_admin)
              roles = { [ROLES.ADMIN]: ROLES.ADMIN };
            this.props.firebase
              .doSignInWithCustomToken(response.data.accessToken)
              .then((authUser) => {
                authUser.user.updateEmail(response.data.email);
                // Create a user in your Firebase realtime database
                return this.props.firebase
                  .user(authUser.user.uid)
                  .set({
                    username: response.data.name,
                    email: response.data.email,
                    avatar_url: response.data.avatar_url,
                    roles,
                  });
              })
              .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
              })
              .catch((error) => {
                this.setState({ error });
              });
          }
        })
        .catch((error) => {
          console.log(error.response.data.error);
          alert(error.response.data.error);
        })
        .finally(() => {});
    }
  }

  onSubmit = (event) => {
    // window.open(
    //   'https://gitlab.sifars.com/oauth/authorize?client_id=da33979108446ef6f8d71ba1b77e518806fcd61129cfd183ea4e765a725df39a&redirect_uri=http://localhost:3000/signin&response_type=code&state=YOUR_UNIQUE_STATE_HASH&scope=read_user',
    //   'firebaseAuth',
    //   'height=315,width=400',
    // );
   	
	 window.location.href = `https://gitlab.sifars.com/oauth/authorize?client_id=${process.env.REACT_APP_GITLAB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SITE_URL}/signin&response_type=code&state=YOUR_UNIQUE_STATE_HASH&scope=read_user`;
	alert(window.location.href)
    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button className="btn btn-primary gitlab-btn" type="submit">
          Sign In with Gitlab
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

const SignInGitlab = compose(
  withRouter,
  withFirebase,
)(SignInGitlabBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
