import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { UserList, UserItem } from '../Users';
import { ServersList } from '../Servers';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const AdminPage = () => (
  <div className="container-fluid">
    {/* <!-- start page title --> */}
    {/* <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <a href="/">Sifars</a>
              </li>
              <li className="breadcrumb-item">
                <a href={ROUTES.SERVERS}>Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Admin</li>
            </ol>
          </div>
          <h4 className="page-title">Servers</h4>
        </div>
      </div>
    </div> */}
    {/* <!-- end page title -->  */}

    <div className="row"> 
      <div className="col-md-8 offset-md-2 site-content">
        <p className="text-center">The Admin Page is accessible by every signed in admin user.</p>
        
        <Switch>
          <Route exact path={ROUTES.SERVERS} component={ServersList} />
          <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
          <Route exact path={ROUTES.ADMIN} component={UserList} />
        </Switch>
      </div>
  </div >
  </div>
);

const condition = (authUser) =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPage);
